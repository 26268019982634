import React, { useEffect } from "react"
import { graphql } from "gatsby"
import {
  GridBox,
  GridSpacer,
  Title,
  Testimonial,
} from "@thedgbrt/logology-ui-components"
import SEO from "../../components/seo"
import { trackSegmentEvent } from "../../helpers/segment"
import { trackAmplitude } from "../../helpers/amplitude"

const TestimonialPage = ({ data }) => {
  const testimonials = data.allPrismicTestimonial.nodes

  useEffect(() => {
    trackSegmentEvent("track", "View testimonials")
    trackAmplitude("View testimonials")
  }, [data])

  return (
    <>
      <SEO title="Testimonials" />
      <GridBox type={5} colSpans={[[2, 7], [2, 7], [3, 7]]} withBgContainer>
        <Title standalone level={1}>
          Testimonials
        </Title>
      </GridBox>

      {testimonials.map((testimonial, index) => (
        <React.Fragment key={index}>
          <GridSpacer desktop="60" mobile="40" tablet="50" />
          <Testimonial
            authorHtml={testimonial.data.author.html}
            image={testimonial.data.picture.url}
            colSpanImage={[[3, 3], [3, 3], [4, 5]]}
            colSpanText={[[4, 8], [4, 8], [6, 11]]}
            gridType={5}
            styleTextWrapper={{ paddingBottom: 0, paddingTop: 0 }}
          >
            <div
              dangerouslySetInnerHTML={{ __html: testimonial.data.text.html }}
            />
          </Testimonial>
        </React.Fragment>
      ))}
    </>
  )
}

export const pageQuery = graphql`
  query {
    allPrismicTestimonial(sort: { fields: data___timestamp, order: DESC }) {
      nodes {
        data {
          author {
            html
          }
          picture {
            url
          }
          text {
            html
          }
        }
      }
    }
  }
`

export default TestimonialPage
